import React from "react";
import styled from "styled-components";
import Container from "../components/Container";
import SEO from "../components/SEO";
import PageHeader from "../components/PageHeader";
import LinkButton from "../components/LinkButton";

export default () => (
  <>
    <SEO title="404 Not Found" />
    <PageHeader title="404 Not Found" />
    <Container>
      <ErrorBody>
        <ErrorHeading>お探しのページは見つかりませんでした</ErrorHeading>
        <LinkButton to="/">ホームへもどる</LinkButton>
      </ErrorBody>
    </Container>
  </>
);

const ErrorBody = styled.div`
  text-align: center;
  margin: 0 0 100px;
`;

const ErrorHeading = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main};
  margin: 0 0 20px;
`;
